header.css-14rq3zg-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
  border-bottom: 1px dotted lightgray !important;
}

header.css-ostsbw-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
  border-bottom: 1px dotted lightgray !important;
}

img.MuiBox-root.css-6jrdpz {
  /* border-radius: 10%; */
  pointer-events: none;
  filter: grayscale(20%);
}
.css-l2jx92{
  box-shadow: 10px 10px 10px #d1cebb;
}

.css-1i382vh{
  background-color: #fff !important;
  border-bottom: 1px dotted lightgray !important;
}
.css-11ig3nh{
  background-color: #fff !important;
  border-bottom: 1px dotted lightgray !important;
}

iframe{ 
   pointer-events: none !important; 
}